// Overrides user components-variables
@import "~bootstrap/scss/_functions.scss";
@import "../variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Google Color Palette defined: http://www.google.com/design/spec/style/color.html

$white: (
	"base": #ffffff
);
$black: (
	"base": #000000
);
$dark: (
	"base": theme-color("dark"),
	"gradient-bg": #640064
);
// $bma: (
//   "gradient-light":
//     lighten(saturate(adjust-hue((#DCF9FB), 25), 11.9), 13.33),
//   "light-badge": lighten(#DCF9FB, 25%),
//   "lighten-5": lighten(#DCF9FB, 25%),
//   "lighten-4": lighten(#DCF9FB, 20%),
//   "lighten-3": lighten(#DCF9FB, 15%),
//   "lighten-2": lighten(#DCF9FB, 10%),
//   "lighten-1": lighten(#DCF9FB, 5%),
//   "base": #DCF9FB,
//   "darken-1": darken(#DCF9FB, 5%),
//   "darken-2": darken(#DCF9FB, 10%),
//   "darken-3": darken(#DCF9FB, 15%),
//   "darken-4": darken(#DCF9FB, 20%),
//   "accent-1": #bdfdff,
//   "accent-2": #8afbff,
//   "accent-3": #57faff,
//   "accent-4": #3df9ff,
//   "gradient-bg": #640064
// );

$bma: (
	"gradient-light": lighten(saturate(adjust-hue((#dffda5), 25), 11.9), 13.33),
	"light-badge": lighten(#dffda5, 25%),
	"lighten-5": lighten(#dffda5, 25%),
	"lighten-4": lighten(#dffda5, 20%),
	"lighten-3": lighten(#dffda5, 15%),
	"lighten-2": lighten(#dffda5, 10%),
	"lighten-1": lighten(#dffda5, 5%),
	"base": #dffda5,
	"darken-1": darken(#dffda5, 5%),
	"darken-2": darken(#dffda5, 10%),
	"darken-3": darken(#dffda5, 15%),
	"darken-4": darken(#dffda5, 20%),
	"accent-1": #bdfdff,
	"accent-2": #8afbff,
	"accent-3": #57faff,
	"accent-4": #3df9ff,
	"gradient-bg": #640064
);

$light: (
	"base": theme-color("light"),
	"gradient-bg": #640064
);
$primary: (
	"gradient-light":
		lighten(saturate(adjust-hue((theme-color("primary")), 25), 11.9), 13.33),
	"light-badge": lighten(theme-color("primary"), 25%),
	"lighten-5": lighten(theme-color("primary"), 25%),
	"lighten-4": lighten(theme-color("primary"), 20%),
	"lighten-3": lighten(theme-color("primary"), 15%),
	"lighten-2": lighten(theme-color("primary"), 10%),
	"lighten-1": lighten(theme-color("primary"), 5%),
	"base": theme-color("primary"),
	"darken-1": darken(theme-color("primary"), 5%),
	"darken-2": darken(theme-color("primary"), 10%),
	"darken-3": darken(theme-color("primary"), 15%),
	"darken-4": darken(theme-color("primary"), 20%),
	"accent-1": #bdfdff,
	"accent-2": #8afbff,
	"accent-3": #57faff,
	"accent-4": #3df9ff,
	"gradient-bg": #640064
);
$secondary: (
	"gradient-light":
		lighten(saturate(adjust-hue((theme-color("secondary")), 25), 11.9), 13.33),
	"light-badge": lighten(theme-color("secondary"), 35%),
	"lighten-5": lighten(theme-color("secondary"), 25%),
	"lighten-4": lighten(theme-color("secondary"), 20%),
	"lighten-3": lighten(theme-color("secondary"), 15%),
	"lighten-2": lighten(theme-color("secondary"), 10%),
	"lighten-1": lighten(theme-color("secondary"), 5%),
	"base": theme-color("secondary"),
	"darken-1": darken(theme-color("secondary"), 5%),
	"darken-2": darken(theme-color("secondary"), 10%),
	"darken-3": darken(theme-color("secondary"), 15%),
	"darken-4": darken(theme-color("secondary"), 20%),
	"gradient-bg": #640064
);
$success: (
	"gradient-light":
		lighten(saturate(adjust-hue((theme-color("success")), 25), 11.9), 13.33),
	"light-badge": lighten(theme-color("success"), 40%),
	"lighten-5": lighten(theme-color("success"), 25%),
	"lighten-4": lighten(theme-color("success"), 20%),
	"lighten-3": lighten(theme-color("success"), 15%),
	"lighten-2": lighten(theme-color("success"), 10%),
	"lighten-1": lighten(theme-color("success"), 5%),
	"base": theme-color("success"),
	"darken-1": darken(theme-color("success"), 5%),
	"darken-2": darken(theme-color("success"), 10%),
	"darken-3": darken(theme-color("success"), 15%),
	"darken-4": darken(theme-color("success"), 20%),
	"accent-1": #e1fff1,
	"accent-2": #aeffd9,
	"accent-3": #7bffc1,
	"accent-4": #62ffb5,
	"gradient-bg": #0064fa
);
$info: (
	"gradient-light":
		lighten(saturate(adjust-hue((theme-color("info")), 25), 11.9), 13.33),
	"light-badge": lighten(theme-color("info"), 40%),
	"lighten-5": lighten(theme-color("info"), 25%),
	"lighten-4": lighten(theme-color("info"), 20%),
	"lighten-3": lighten(theme-color("info"), 15%),
	"lighten-2": lighten(theme-color("info"), 10%),
	"lighten-1": lighten(theme-color("info"), 5%),
	"base": theme-color("info"),
	"darken-1": darken(theme-color("info"), 5%),
	"darken-2": darken(theme-color("info"), 10%),
	"darken-3": darken(theme-color("info"), 15%),
	"darken-4": darken(theme-color("info"), 20%),
	"accent-1": #feffff,
	"accent-2": #cbf5ff,
	"accent-3": #98ecff,
	"accent-4": #7fe7ff,
	"gradient-bg": #640064
);
$warning: (
	"gradient-light":
		lighten(saturate(adjust-hue((theme-color("warning")), 25), 11.9), 13.33),
	"light-badge": lighten(theme-color("warning"), 25%),
	"lighten-5": lighten(theme-color("warning"), 25%),
	"lighten-4": lighten(theme-color("warning"), 20%),
	"lighten-3": lighten(theme-color("warning"), 15%),
	"lighten-2": lighten(theme-color("warning"), 10%),
	"lighten-1": lighten(theme-color("warning"), 5%),
	"base": theme-color("warning"),
	"darken-1": darken(theme-color("warning"), 5%),
	"darken-2": darken(theme-color("warning"), 10%),
	"darken-3": darken(theme-color("warning"), 15%),
	"darken-4": darken(theme-color("warning"), 20%),
	"accent-1": #fff5ef,
	"accent-2": #ffe5d8,
	"accent-3": #fff6f3,
	"accent-4": #ffe3da,
	"gradient-bg": #fafafa
);
$danger: (
	"gradient-light":
		lighten(saturate(adjust-hue((theme-color("danger")), 25), 11.9), 13.33),
	"light-badge": lighten(theme-color("danger"), 30%),
	"lighten-5": lighten(theme-color("danger"), 25%),
	"lighten-4": lighten(theme-color("danger"), 20%),
	"lighten-3": lighten(theme-color("danger"), 15%),
	"lighten-2": lighten(theme-color("danger"), 10%),
	"lighten-1": lighten(theme-color("danger"), 5%),
	"base": theme-color("danger"),
	"darken-1": darken(theme-color("danger"), 5%),
	"darken-2": darken(theme-color("danger"), 10%),
	"darken-3": darken(theme-color("danger"), 15%),
	"darken-4": darken(theme-color("danger"), 20%),
	"accent-1": #ffeef1,
	"accent-2": #ffd6db,
	"accent-3": #ffecee,
	"accent-4": #ffd3d7,
	"gradient-bg": #640064
);

$colors: (
	"white": $white,
	"black": $black,
	"dark": $dark,
	"light": $light,
	"primary": $primary,
	"secondary": $secondary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"bma": $bma
);
