// ================================================================================================
//     File Name: content.scss
//     Description: Page content level SCSS for different screen size, layout and device.
//     ----------------------------------------------------------------------------------------------
//     Item Name: Vuexy React Admin Template
//     Version: 1.0
//     Author: PIXINVENT
//     Authuseror URL: http://www.themeforest.net//pixinvent
// ================================================================================================

// Contest margin left for expanded & collapsed stats width & margin left
// ================================================================================================
html {
	font-size: $base-font-size;
	height: 100%;
	letter-spacing: 0.01rem;
	body {
		height: 100%;
		background-color: $body-bg;
		direction: $body-direction;
		.content {
			padding: 0;
			position: relative;
			transition: 300ms ease all;
			backface-visibility: hidden;
			min-height: calc(100% - 4rem);
			margin-left: $menu-expanded-width;
			&.app-content {
				overflow: hidden;
				&.show-overlay {
					.content-overlay {
						z-index: 10;
						opacity: 1;
						~ .header-navbar-shadow {
							background: linear-gradient(
								180deg,
								rgba(44, 48, 60, 0.9) 44%,
								rgba(44, 48, 60, 0.43) 73%,
								rgba(44, 48, 60, 0)
							);
						}
					}
				}
				.content-overlay {
					position: fixed;
					opacity: 0;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba($pure-black, 0.5);
					cursor: pointer;
					transition: all 0.7s;
					z-index: -1;
					&.show {
						z-index: 12;
					}
				}
				.content-area-wrapper {
					height: calc(100% - #{$navbar-height});
					margin: calc(#{$navbar-height} + 2.7rem) #{$content-padding} 0;
					// padding: calc(#{$content-padding} - 0.4rem) #{$content-padding};
					display: flex;
					position: relative;
					.content-wrapper {
						margin-top: 0;
						height: calc(100vh - 13rem);
					}
				}
			}
			.content-wrapper {
				padding: calc(#{$content-padding} - 0.4rem) #{$content-padding} 0;
				margin-top: $navbar-height + 1;
				min-height: calc(100vh - 4.62rem);
				//min-height: calc(100vh);
				.content-header-title-BreadCrumbs {
					font-weight: 300;
					color: #333333;
					margin-right: $spacer;
					font-size: 24px;
				}

				.content-header-title {
					font-weight: 500;
					color: $gray-400;
					margin-right: $spacer;
				}
				.content-header-right {
					.dropdown-toggle {
						&::after {
							display: none;
						}
					}
					.btn-icon {
						padding: 0.8rem;
					}
				}
			}
		}
		//for navbar hidden
		.vertical-layout.navbar-hidden {
			.app-content {
				.header-navbar-shadow {
					display: none;
				}
				.content-wrapper {
					padding: 2.2rem;
					margin-top: 0;
					min-height: calc(100vh - 4rem);
				}
				.content-area-wrapper {
					padding: 0;
					margin-top: 2.5rem;
				}
			}
		}

		// for static navbar
		.vertical-layout.navbar-static {
			.app-content {
				.header-navbar-shadow {
					display: none;
				}
				.navbar-container {
					padding-left: 2.2rem;
					padding-right: 1.2rem;
				}
				.content-wrapper {
					padding: 2.2rem;
					margin-top: 0;
					padding-top: 1rem;
				}
				.content-area-wrapper {
					margin-top: 1rem;
				}
			}
		}

		// for sticky navbar
		.vertical-layout {
			&.menu-collapsed {
				.header-navbar.fixed-top {
					left: 60px;
					width: auto;
				}
			}
			&.navbar-sticky {
				.app-content {
					.header-navbar-shadow {
						display: none;
					}
					.navbar-container {
						padding-left: 2.2rem;
						padding-right: 1rem;
					}
					.content-wrapper {
						padding: 2.2rem;
						margin-top: 4.65rem;
					}
				}
			}
		}

		// navbar search width for sticky and static navbar
		.vertical-layout.navbar-static,
		.vertical-layout.navbar-sticky {
			.navbar-container {
				padding-left: 2.2rem;
				padding-right: 1rem;
			}
		}

		// Fixed layout
		.vertical-layout.fixed-footer,
		.horizontal-layout.fixed-footer {
			.app-content {
				margin-bottom: 3rem;
			}
			.footer {
				position: fixed;
				z-index: 1030;
				right: 0;
				left: 0;
				bottom: 0;
				background: $white;
				box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.15);
			}
		}

		.vertical-layout .menu-swipe-area {
			width: 30px;
			position: fixed;
			left: 0;
			top: 0;
			z-index: 1032;
			height: 100vh;
		}

		// paragraph line-height
		p {
			line-height: 1.5rem;
		}

		.bg-full-screen-image {
			background: url("../../../img/pages/vuesax-login-bg.jpg") no-repeat center
				center;
			background-size: cover;
		}

		/*
        * Blank page
        */

		.blank-page {
			.content {
				margin-left: 0;
				// overflow-x hidden, overflow for y in sm screen issue resolved default
				&.app-content {
					overflow: overlay;
					overflow-x: hidden;
				}
			}
			// blank page content-wrapper
			.content-wrapper {
				padding: 0 !important;
				margin-top: 0;
				// content to be in center horizontally and vertically
				.flexbox-container {
					display: flex;
					align-items: center;
					height: 100vh;
					justify-content: center;
					overflow: hidden;
				}
			}
		}
		//Remove left margin for 1 column layout
		&[data-col="1-column"] {
			.content,
			.footer {
				margin-left: 0px !important;
			}
		}
	}
	.pace {
		.pace-progress {
			background: $primary;
		}
	}
}

.app-content.center-layout {
	overflow: hidden;
}

/*
* Col 3 layout for detached and general type
*/

@include media-breakpoint-up(lg) {
	body {
		// Normal sidebar
		.content-right {
			width: calc(100% - #{$sidebar-width});
			float: right;
		}
		.content-left {
			width: calc(100% - #{$sidebar-width});
			float: left;
		}
		// Detached sidebar
		.content-detached {
			width: 100%;
			&.content-right {
				float: right;
				margin-left: -$sidebar-width;
				.content-body {
					margin-left: calc(#{$sidebar-width} + #{$content-padding});
				}
			}
			&.content-left {
				float: left;
				margin-right: -$sidebar-width;
				.content-body {
					margin-right: calc(#{$sidebar-width} + #{$content-padding});
				}
			}
		}
	}
	.sidebar-right.sidebar-sticky {
		float: right !important;
		margin-left: -$sidebar-width;
		width: $sidebar-width !important;
		margin-top: $navbar-height + 1;
	}
	//left sticky sidebar
	[data-col="content-left-sidebar"] {
		.sticky-wrapper {
			float: left;
		}
	}
}

.truncate {
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
}

@include media-breakpoint-down(xs) {
	html body .content .content-wrapper {
		padding: calc(#{$content-padding} - 0.4rem) calc(#{$content-padding} - 1rem)
			0;
	}
}

@include media-breakpoint-down(xs) {
	body {
		&.navbar-static,
		&.navbar-sticky {
			.app-content {
				.header-navbar {
					.navbar-container {
						padding-left: 1rem;
						padding-right: 0.5rem;
					}
				}
			}
		}
	}
}

// For Fullscreen IE
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	html.full-screen {
		width: 100%;
		.content.app-content {
			height: 100%;
			overflow: scroll;
			overflow-x: hidden;
		}
	}
}
.body-background {
	// background-image: url("../../../img/backgrounds/photo-1471970471555-19d4b113e9ed.jpeg");
	//background-opacity: 0.6;
	// background-repeat: no-repeat;
	// background-size: 100% 100%;
	// background-attachment: fixed;
	// background-position: bottom;
	//height: 100%;

	// background-color: #E5E5E5;
	background-color: #bfe2aa;
}

.vertical-layout {
	.main-menu {
		.navigation {
			.menu-content {
				li a {
					svg:first-child {
						margin-right: 1.38rem !important;
					}
					.menu-toggle-icon {
						right: 9px;
					}
				}
			}
		}
	}
	&.menu-collapsed {
		.main-menu.collapsed {
			width: 80px;
			transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
			.badge,
			svg.menu-toggle-icon,
			.brand-text,
			.navigation-header,
			ul.menu-content {
				display: none;
			}
		}
		.app-content {
			margin-left: 80px;
			.header-navbar {
				width: calc(100% - 4.4rem - 80px);
			}
		}
		.footer {
			margin-left: 80px;
		}
	}
}

@media (max-width: 1200px) {
	.vertical-layout {
		.app-content {
			margin-left: 0;
			.header-navbar.floating-nav {
				width: calc(100% - 4.4rem) !important;
			}
		}
		.footer {
			margin-left: 0;
		}
		.main-menu:not(.hide-sidebar) {
			& ~ .sidenav-overlay {
				display: block;
			}
		}
	}
}

@media (max-width: 576px) {
	.vertical-layout {
		.app-content {
			.header-navbar.floating-nav {
				width: calc(100% - 2.4rem) !important;
			}
		}
	}
}
