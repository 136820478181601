@font-face {
	font-family: "feather";
	src: url("../../fonts/feather.eot?t=1501834582692"); /* IE9*/
	src: url("../../fonts/feather.eot?t=1501834582692#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("../../fonts/feather.woff?t=1501834582692") format("woff"),
		/* chrome, firefox */ url("../../fonts/feather.ttf?t=1501834582692")
			format("truetype"),
		/* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
			// url("../../fonts/feather.svg")
		url("../../fonts/feather.svg?t=1501834582692#feather") format(
				"svg"
			); /* iOS 4.1- */
}
.primary-color {
	color: #068f97 !important;
}
.underline {
	border-bottom: 1px solid gainsboro;
}
.sarabun-font {
	font-family: "Sarabun" !important;
}
.f-14 {
	font-size: 14px;
}
.f-18 {
	font-size: 18px;
}
.rdt_TableCol div div {
	font-size: 14px;
	font-weight: bold;
	text-align: center;
}
.custombtinmodal {
	background-color: #267659 !important;
}
.custombtCancel {
	border-color: #ff9630 !important;
	color: #ff9630 !important;
	background-color: transparent !important;
	border: 1px solid #ff9630 !important;
	&:hover {
		border-color: #ff9630 !important;
		border: 1px solid #ff9630 !important;
		color: #ff9630 !important;
		background-color: rgba(233, 236, 239, 0.5) !important;
		box-shadow: 0 8px 25px -8px #fff !important;
	}
	&:active {
		background: #ff9630 !important;
		color: #fff !important;
	}
}
.dotActive {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 5px;
	background: #267659;
}
.dotunActive {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 5px;
	background: #ea5455;
}
.pPadding4px {
	padding-left: 4px !important;
}
ul li {
	list-style-type: circle;
}

.navbar-wrapper ul li {
	list-style-type: none;
}

.notClick {
	pointer-events: none;
	opacity: 50% !important;
}
.customRow {
	padding: 10px !important;
	vertical-align: unset !important;
}
.maxWidth300 {
	max-width: 300px;
}
.maxWidth350 {
	max-width: 350px;
}
.zIndex0 {
	z-index: 0;
}
.errorBorder {
	border: 1px solid red;
	border-radius: 5px;
}
// custom progressBar

.content-progress {
	width: 315px;
	height: 200px;
	margin: auto;
	margin-top: 35%;
}
.card-progress {
	width: 300px;
	height: 70px;
	padding: 10px;
}
.marginProgressCustom {
	margin: auto 0;
	height: 18px;
}
.m0Auto {
	margin: auto 0;
}
.bg-red {
	background-color: red;
}
.color-w {
	color: #fff;
}

// for datepicker
.ant-input {
	color: #5f5f5f;
	height: calc(1.25 * 1em + 1.4rem + 1px);
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	padding: 7px;
	width: 100%;
}
.react-datepicker-wrapper {
	display: inline-block;
	padding: 0;
	border: 0;
	width: 100% !important;
}
.datepicker-error {
	border: 1px solid #ea5455;
	border-radius: 6px;
}
.z-index10 {
	z-index: 10;
}

// for inputfiles ที่ชื่อไฟล์ยาวเกินกรอบ || inputfiles ลอย
.custom-file {
	position: relative !important;
	z-index: 0 !important;
}
.custom-file-label {
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}
.postion-calendar {
	position: absolute;
	right: 25px;
	top: 12px;
}
.w-70 {
	width: 70px;
}

//custom for progress bar
.mainBoxFixed {
	position: fixed;
	background: rgba(255, 255, 255, 0.5);
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 2000;
	.progress {
		height: 15px;
		width: 300px;
		margin: auto;
	}
}
.h-10px {
	height: 10px;
}
.relativeCenter {
	position: relative;
	top: 50%;
}
.custom-file {
	.custom-file-label {
		margin-right: 0;
		&::after {
			font-family: "feather";
			font-style: normal;
			font-weight: 400;
			font-display: block;
			content: "\e8e1" !important;
			width: 35px;
			justify-content: center;
			display: flex;
			padding: 6px 0 0 0;
			font-size: 13px;
		}
	}
}
.tooltip.show.bs-tooltip-auto {
	z-index: 9999999 !important;
}
.fonts-14 {
	font-size: 14px;
}

.sticky {
	position: sticky;
	top: 100;
	left: 0;
	right: 0;
	z-index: 5000;
}
